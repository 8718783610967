<template>
  <business-guide-card-wrapper
    class="management-team-card cursor-move"
    @click="$emit('edit', member)"
  >
    <!-- Grid view -->
    <template #top-grid>
      <actions-dropdown
        v-if="canEdit && !getStepIsCompleted('managementTeamCompleted')"
        ref="actionsDropdown"
        class="actions"
        :entity="member"
        :delete-disabled="deleteDisabled"
        :copy-disabled="copyDisabled"
        :edit-disabled="editDisabled"
        @edit="$emit('edit', $event)"
        @copy="$emit('copy', $event)"
        @delete="$emit('delete', $event)"
      />
      <div
        class="avatar-holder circle"
        :class="{'default': !member.image}"
        :style="image"
      />
    </template>
    <template #bottom-grid>
      <div class="ib-card-bottom d-flex flex-column justify-content-around">
        <div>
          <p class="m-0 member-name grid text-center three-dots mb-2">
            {{ member.name }}
          </p>
          <p class="member-position grid text-center three-dots m-0 px-2 mb-2">
            {{ member.position }}
          </p>
        </div>
        <p class="member-description pre-formatted grid three-dots m-0 px-2">
          {{ member.description }}
        </p>
      </div>
    </template>
    <!-- List view -->
    <template #list>
      <div>
        <div
          class="avatar-holder circle mr-3"
          :class="{'default': !member.image }"
          :style="imageSmall"
        />
      </div>
      <div class="d-flex flex-grow-1 flex-column">
        <span class="member-name list three-dots">{{ member.name }}</span>
        <span class="member-position list three-dots">{{ member.position }}</span>
      </div>

      <div class="d-flex align-items-center">
        <actions-dropdown
          v-if="canEdit && !getStepIsCompleted('managementTeamCompleted')"
          ref="actionsDropdown"
          class="actions"
          :entity="member"
          :delete-disabled="deleteDisabled"
          :copy-disabled="copyDisabled"
          :edit-disabled="editDisabled"
          @edit="$emit('edit', $event)"
          @copy="$emit('copy', $event)"
          @delete="$emit('delete', $event)"
        />
      </div>
    </template>
  </business-guide-card-wrapper>
</template>

<script>
import ActionsDropdown from '@/views/Home/StoryMode/Components/ActionsDropdown'
import BusinessGuideCardWrapper from '@/views/Home/StoryMode/Components/Cards/BusinessGuideCardWrapper'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import { cardImage } from '@/helpers/imageHelper'
import { getImageBySize } from '@/helpers/imageSizeHelper'
import { mapGetters } from 'vuex'

export default {
  name: 'ManagementTeamCard',

  components: {
    BusinessGuideCardWrapper,
    ActionsDropdown
  },

  mixins: [MixinIdeaRoles],

  provide () {
    return {
      view: this.view
    }
  },

  props: {
    member: {
      type: Object,
      required: true
    },
    view: {
      type: String,
      validator: value => ['grid', 'list'].includes(value),
      default: 'grid'
    },
    deleteDisabled: {
      type: Boolean,
      default: false
    },
    copyDisabled: {
      type: Boolean,
      default: false
    },
    editDisabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters('idea', ['getStepIsCompleted']),

    image () {
      return cardImage(
        this.member.image
          ? getImageBySize(this.member.image.sizes, 'medium')
          : ''
      )
    },

    imageSmall () {
      return cardImage(
        this.member.image
          ? getImageBySize(this.member.image.sizes, 'small')
          : ''
      )
    }
  }
}
</script>

<style scoped>
.management-team-card.grid >>> .ib-card-top {
  min-height: 150px;
}

.management-team-card.grid .ib-card-bottom {
  min-height: 170px;
  padding: 10px;
}

.management-team-card .avatar-holder.default {
  background-image: url('~@/assets/img/icons/management-team-icon-in-circle.svg');
}

.management-team-card.list >>> div > .circle {
  background-size: cover;
}

.member-name {
  line-height: 1.18;
  font-size: 18px;
}

.member-position {
  font-size: 14px;
  opacity: 0.7;
}

.member-description {
  font-size: 14px;
}

.member-name,
.member-position,
.member-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.member-name.grid,
.member-position.grid {
  -webkit-line-clamp: 2;
}

.member-description.grid {
  -webkit-line-clamp: 3;
}

.member-name.list,
.member-position.list {
  -webkit-line-clamp: 1;
  word-break: break-all;
}
</style>
